import React from 'react';

const Card = ({id, name, email}) => {
    // const { id, name, email } = props;
    return (
        <div className='bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5'>
            {/* making use of tachyons.io instead of manually defining/tweaking css */}
            <img src={`https://robohash.org/${id}?200x200`} alt='robots' /> {/* self closing tag is allowed in react */}
            {/* use image generator api */}
            <div>
                <h2>{name}</h2>
                <h2>{email}</h2>
                {/* notice the destructing concept of react, cutting down repetition on props keyword */}
            </div>
        </div>
    );
}

export default Card;
// the hallmark of component
