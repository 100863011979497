import React, { Component } from 'react';
import CardList from './CardList';
import SearchBox from './SearchBox';
import { robots } from './robots';
import './App.css';

class App extends Component {
    // if you wanna use state, you gotta extends component
    constructor() {
        super()
        this.state = {
            robots: robots,
            searchfield: ''
        }
    }
    onSearchChange = (event) => {
        this.setState({ searchfield: event.target.value })
        // console.log(event.target.value);
    }
    render() {
        const filteredRobots = this.state.robots.filter(robots => { return robots.name.toLowerCase().includes(this.state.searchfield.toLowerCase()); });
        return (
            <div className='tc'>
                <h1>Robofriends</h1>
                <SearchBox searchChange={this.onSearchChange} />
                <CardList robots={filteredRobots} />
                {/* <CardList robots={this.state.robots} /> */}
            </div>
        )
    }
}

export default App;