import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import Hello from './Hello';
// import Card from './Card';
// import CardList from './CardList';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tachyons';
// import { robots } from './robots';
// notice that there's no 'export default' anywhere in robots.js, curly braces is meant for destructuring the array within robots.js

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <CardList robots={robots} /> */}
    {/* card based component to cut down repetition */}
    {/* <Card id={robots[1].id} name={robots[1].name} email={robots[1].email}/>
    <Card id={robots[0].id} name={robots[0].name} email={robots[0].email}/>
    <Card id={robots[2].id} name={robots[2].name} email={robots[2].email}/>
    <Card id={robots[3].id} name={robots[3].name} email={robots[3].email}/>
    <Card id={robots[4].id} name={robots[4].name} email={robots[4].email}/>
    <Card id={robots[5].id} name={robots[5].name} email={robots[5].email}/>
    <Card id={robots[6].id} name={robots[6].name} email={robots[6].email}/>
    <Card id={robots[7].id} name={robots[7].name} email={robots[7].email}/>
    <Card id={robots[8].id} name={robots[8].name} email={robots[8].email}/>
    <Card id={robots[9].id} name={robots[9].name} email={robots[9].email}/> */}
    {/* <Hello greeting={'Hello React Ninja'} />  */}
    {/* notice that we are passing what's known as react props into react components, injection... */}
    {/* <h2>THIS IS SPARTA!!</h2>
    <p>Test</p> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
